import React, { ReactElement } from "react";
import MainBanner from "@components/vehicleinjury/mainBanner";
import VehicleInjuryWrapper from "@components/layouts/vehicleinjuryWrapper";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import { getPageProps } from "@services/initial-calls";
import { useDomainContext } from "@hooks/useDomainContext";
import Settlement from "@components/vehicleinjury/settlement";
import HireAttorney from "@components/vehicleinjury/hireAttorney";
import SuitTime from "@components/vehicleinjury/suitTime";
import Compensation from "@components/vehicleinjury/compensation";
import AccidentTypes from "@components/vehicleinjury/accidentTypes";
import WhyUs from "@components/vehicleinjury/whyUs";
import Disclaimer from "@components/shared/legal/Disclaimer";
const OffersList = dynamic(() => import("@components/shared/offersList"));

const Lawsuit = dynamic(() => import("@components/vehicleinjury/lawsuit"));
const AccidentSettlement = dynamic(
    () => import("@components/vehicleinjury/whatWeDo"),
);

export default function VehicleInjuryHome({
    locale,
}: {
    locale: string;
}): ReactElement {
    const { domain } = useDomainContext();
    return (
        <VehicleInjuryWrapper>
            <MainBanner />
            <section>
                <OffersList offers={domain?.defaultOffers} />
            </section>
            {locale !== "es" ? (
                <>
                    <AccidentSettlement />
                    <Lawsuit />
                    <HireAttorney />
                    <Settlement />
                    <SuitTime />
                    <Compensation />
                    <AccidentTypes />
                    <WhyUs />
                    <Disclaimer />
                </>
            ) : (
                <></>
            )}
        </VehicleInjuryWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
